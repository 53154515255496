import React, { useState, useEffect } from 'react'
import { func, object, bool } from 'prop-types'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import cx from 'classnames'
import get from 'lodash/get'
import range from 'lodash/range'

import './vehicle.scss'

const defaultProps = {
  second: false,
}

const propTypes = {
  submitData: func.isRequired,
  handleNext: func,
  captive: object.isRequired,
  second: bool,
  nextStepSecond: func,
  prevStepSecond: func,
}

const VehicleDate = ({
  submitData,
  handleNext,
  captive,
  second,
  nextStepSecond,
  prevStepSecond,
}) => {
  const [years, setYears] = useState(null)

  useEffect(() => {
    let req = { canceled: false }
    getYears(req)
    return () => {
      req.canceled = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getYears = async (req) => {
    // OLD const url = 'https://xuo621mpt2.execute-api.us-east-1.amazonaws.com/dev/v1/carquery'
    const url = `https://rwzqgyxmfd.execute-api.us-east-1.amazonaws.com/prod/v1/years`
    await axios(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (!req.canceled) {
        setYears(res?.data?.data)
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (second) {
      submitData({
        vehicle_year_second: e.target.value,
      })
      nextStepSecond()
    } else {
      submitData({
        vehicle_year: e.target.value,
      })
      handleNext('vehicleDate')
    }
  }

  const renderYearOptions = () => {
    // const ranegeOfYears = range(years.max_year, years.min_year)
    const picked = second
      ? get(captive, 'data.vehicle_year_second', null)
      : get(captive, 'data.vehicle_year', null)

    const reversedYears = years.slice().reverse()

    return reversedYears.map(({ year }) => (
      <button
        key={year}
        value={year}
        onClick={handleSubmit}
        type="button"
        className={cx('btn', 'btn-secondary', picked === year && 'picked')}
      >
        {year}
      </button>
    ))
  }
  return (
    <>
      <div id="vehicleDate">
        <Helmet>
          <script>{`window.initiateJquery();`}</script>
        </Helmet>
        <strong className="h4">Select your {second && 'second'} vehicle year</strong>
        {years ? (
          <form action="#" className="form-validation" noValidate="novalidate">
            {renderYearOptions()}
          </form>
        ) : (
          <div>Loading...</div>
        )}
      </div>
      {second && (
        <span className="previous" onClick={prevStepSecond}>
          &larr;&nbsp; Previous Question
        </span>
      )}
    </>
  )
}

VehicleDate.defaultProps = defaultProps
VehicleDate.propTypes = propTypes

export default VehicleDate
