import { connect } from 'react-redux'

import Address from './address'

import { nextStep, submitData } from '../../../reducers/captive'

export default connect((state) => state, {
  nextStep,
  submitData,
})(Address)
