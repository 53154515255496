export const short = [
  'State Farm',
  'GEICO',
  'Progressive',
  'Allstate',
  'USAA',
  'Liberty Mutual',
  'Farmers',
  'Nationwide',
  'Travelers',
  'American Family',
]

export const long = [
  '(Not Listed)',
  '21st Century',
  'AAA',
  'AABCO',
  'AARP',
  'Access Insurance',
  'Ace',
  'ACE Group',
  'Aegis Security',
  'AETNA',
  'Affirmative',
  'AFLAC',
  'AHCP',
  'AIG / American General',
  'AIU Insurance',
  'Alfa Insurance',
  'All Nation',
  'All Risk',
  'Allianz',
  'Allied',
  'Allstate',
  'American Alliance Insurance',
  'American Automobile Insurance',
  'American Casualty',
  'American Deposit Insurance',
  'American Direct Business Insurance',
  'American Empire Insurance',
  'American Family',
  'American Financial',
  'American Health Underwriters',
  'American Home Assurance',
  'American Insurance',
  'American International Insurance',
  'American International Pacific',
  'American International South',
  'American Manufacturers',
  'American Mayflower Insurance',
  'American Medical Securities',
  'American Motorists Insurance',
  'American National',
  'American Premier Insurance',
  'American Protection Insurance',
  'American Reliable',
  'American Republic',
  'American Savers Plan',
  'American Service Insurance',
  'American Skyline Insurance',
  'American Spirit Insurance',
  'American Standard Insurance',
  'American States',
  'AmeriPlan',
  'Ameriprise',
  'Amerisure',
  'Amica',
  'Answer Financial',
  'Anthem',
  'API',
  'Arbella',
  'Armed Forces Insurance',
  'Assigned Risk',
  'Associated Indemnity',
  'Assurant',
  'Atlanta Casualty',
  'Atlantic Indemnity',
  'Atlantis',
  'Austin Mutual',
  'Auto Club Insurance Company',
  'Auto Owners',
  'Avomark',
  'AXA Advisors',
  'Badger Mutual',
  'Bankers & Shippers',
  'Bankers Life and Casualty',
  'Banner Life',
  'Best Agency USA',
  'Blue Cross / Blue Shield',
  'Bonneville',
  'Boston Old Colony',
  'Bristol West',
  'Brooke Insurance',
  'Builders',
  'Cal Farm Insurance',
  'California Casualty',
  'California State Automobile Association',
  'Camden',
  'Capital Choice',
  'Care Entree',
  'Cascade National Ins',
  'Casualty Assurance',
  'Centennial',
  'Charter Oak',
  'Chase Insurance Group',
  'Chubb',
  'Church Mutual',
  'Cigna',
  'Cincinnati Financial',
  'Citizens',
  'Clarendon',
  'Clarendon American Insurance',
  'Clarendon National Insurance',
  'Cloverleaf',
  'CNA',
  'Colonial Insurance',
  'Combined',
  'Commercial Union',
  'Commonwealth',
  'Comparison Market',
  'Continental Casualty',
  'Continental Divide Insurance',
  'Continental Insurance',
  'Cotton States',
  'Cottonwood',
  'Country Financial',
  'Country Insurance',
  'Countrywide',
  'Credit Union',
  'Criterion',
  'CSE Insurance Group',
  'CUNA Mutual',
  'Dairyland Insurance',
  'Deerbrook',
  'Direct Auto',
  'Dixie',
  'Economy',
  'eHealthInsurance Services',
  'Electric',
  'Electric Insurance',
  'EMC',
  'Empire',
  'Ensure',
  'Equitable Life & Casualty Insurance',
  'Erie',
  'Esurance',
  'Facility',
  'Farm and Ranch',
  'Farm Bureau/Farm Family/Rural',
  'Farmers',
  'Farmers Union',
  'Farmland',
  'Federal',
  'Federated',
  'Federated American',
  'Federated Group',
  'Fidelity Investments Life',
  'Fidelity National',
  'Fidelity Security Life',
  'FinanceBox.com',
  'Financial Indemnity',
  'Fire and Casualty Insurance Co of CT',
  'Fire Insurance Exchange',
  'Firemans Fund',
  'First American',
  'First Financial',
  'First General',
  'First National',
  'Ford',
  'Foremost',
  'Foresters',
  'Fortis',
  'Franklin',
  'Garden State Life Insurance',
  'GEICO',
  'General Insurance',
  'Genworth Financial',
  'Globe Life',
  'GMAC',
  'Golden Rule',
  'Government Employees Insurance',
  'Grange',
  'Great American',
  'Great Way',
  'Great West',
  'Grinnell Mutual',
  'Guaranty National Insurance',
  'Guardian',
  'Guide One',
  'Hanover',
  "Hanover Lloyd's Insurance Company",
  'Hartford',
  'Hawkeye Security',
  'Health Benefits Direct',
  'Health Choice One',
  'Health Net',
  'Health Plus of America',
  'HealthMarkets',
  'HealthShare America',
  'Heritage',
  'Horace Mann',
  'Humana',
  'IAB',
  'IFA Auto Insurance',
  'IGF Insurance',
  'Infinity',
  'Infinity Insurance',
  'Infinity National Insurance',
  'Infinity Select Insurance',
  'Insurance Insight',
  'Integon',
  'Interstate',
  'John Deere',
  'John Hancock',
  'Kaiser Permanente',
  'Kaiser Permanente of California',
  'Kemper',
  'Landmark American Insurance',
  'Leader National Insurance',
  'Leader Preferred Insurance',
  'Leader Specialty Insurance',
  'League General',
  'Liberty Insurance Corp',
  'Liberty Mutual',
  'Liberty National',
  'Liberty Northwest Insurance',
  'Life',
  'Lincoln Benefit',
  'LTC Financial Partners',
  'Lumbermens Mutual',
  'Mapfre Insurance',
  'Marathon',
  'Markel American',
  'Market American',
  'Maryland Casualty',
  'Mass Mutual',
  'Mega / Midwest',
  'MEGA Life and Health',
  'Mendota',
  'Merastar',
  'Mercury',
  'MetLife Auto and Home',
  'Metropolitan',
  'Mid Century Insurance',
  'Mid-Continent Casualty',
  'Middlesex Insurance',
  'Midland National Life',
  'Midwest Mutual',
  'Millbank',
  'Millers Mutual',
  'Milwaukee Guardian',
  'Modern Woodmen of America',
  'Mony Group',
  'Mortgage Protection Bureau',
  'Motor Credit',
  'Mountain Laurel',
  'Mutual Insurance',
  'Mutual of New York',
  'Mutual Of Omaha',
  "Nat'l Farmers Union",
  'National Ben Franklin Insurance',
  'National Casualty',
  'National Continental Insurance',
  'National Fire Insurance',
  'National Health Insurance',
  'National Indemnity',
  'National Insurance',
  'National Merit',
  'National Surety Corp',
  'National Union Fire Insurance',
  'Nationwide',
  'New England Financial',
  'New York Life',
  'None',
  'North American',
  'North Pacific',
  'North Pointe',
  'Northern Capital',
  'Northland',
  'Northwest Pacific',
  'Northwestern Mutual Life',
  'Northwestern Pacific Indemnity',
  'Ohio Casualty',
  'Ohio National',
  'Old Republic',
  'Olympia',
  'Omaha',
  'Omni Insurance',
  'Oregon Mutual',
  'Orion Insurance',
  'Other',
  'Other / Not Listed',
  'Pacific Insurance',
  'Pafco General Insurance',
  'Patriot General Insurance',
  'Peachtree',
  'Peak Property and Casualty Insurance',
  'PEMCO',
  'Penn Mutual',
  'Pennsylvania Life',
  'Phoenix',
  'Physicians',
  'Pinnacle',
  'Pioneer Life',
  'Plymouth Rock Assurance',
  'Preferred Abstainers',
  'Preferred Mutual',
  'Premier',
  'Prestige',
  'Primerica',
  'Principal Financial',
  'Progressive',
  'Protective Casualty',
  'Protective Life',
  'Provident',
  'Prudential',
  'PURE: Privilege Underwriters Reciprocal Exchange',
  'Quality',
  'Ramsey',
  'Ranger',
  'RBC Liberty',
  'Regal',
  'Reliance Insurance',
  'Republic Indemnity',
  'Response',
  'Rockford Mutual',
  'Rodney D. Young',
  'Rural Mutual Insurance',
  'SAFECO',
  'Safeway Insurance',
  'SBLI',
  'Sea West Insurance',
  'Secura',
  'Security Insurance',
  'Sedgwick James',
  'Sentinel Insurance',
  'Sentry',
  'Shelter',
  'Spectrum',
  'St. Paul',
  'Standard Fire Insurance Company',
  'State and County Mutual Fire Insurance',
  'State Farm',
  'State Fund',
  'State Mutual',
  'State National Insurance',
  'Sun Coast',
  'Superior American Insurance',
  'Superior Guaranty Insurance',
  'Superior Insurance',
  'Sure Health Plans',
  'Sutter',
  'The Ahbe Group',
  'The Credo Group',
  'The General',
  'The Hanover',
  'The Hartford',
  'TICO Insurance',
  'TIG Countrywide Insurance',
  'Titan',
  'Total',
  'Tower',
  'TransAmerica',
  'Travelers',
  'Tri-State Consumer Insurance',
  'Trinity Universal',
  'Trust Hall',
  'Twentieth Century',
  'Twin City Fire Insurance',
  'UniCare',
  'Uniguard',
  'Union',
  'United American',
  'United American/Farm and Ranch',
  'United Health',
  'United Health One',
  'United Insurance',
  'United Pacific Insurance',
  'United Security',
  'United Services Automobile Association',
  'United States Fideli',
  'Unitrin Direct',
  'Universal Underwriters Insurance',
  'US Financial',
  'US Health Advisors',
  'US Health and Freedom Life',
  'US Health Group',
  'USA Benefits/Continental General',
  'USAA',
  'USF and G',
  'Utah Home and Fire',
  'Utica',
  'Vasa North Atlantic',
  'Vigilant',
  'Viking Insurance',
  'Wawaunesa',
  'Wellington',
  'Wellpoint',
  'West American',
  'West Bend Mutual',
  'West Field',
  'West Plains',
  'Western & Southern Life',
  'Western Mutual',
  'Western National',
  'Western Southern Life',
  'Westfield',
  'William Penn',
  'Windsor Insurance',
  'Windstar',
  'Wisconsin Mutual',
  'Woodlands Financial Group',
  'Workmans Auto',
  'World Insurance',
  'Worldwide',
  'Yellow Key',
  'Yosemite',
  'Zurich North America',
]
