import { connect } from 'react-redux'

import Married from './married'

import { submitData } from '../../../reducers/captive'

export default connect(
  (state) => ({
    captive: state.captive,
  }),
  {
    submitData,
  },
)(Married)
