import React from 'react'

const LeadIDinput = () => (
  <input
    id="leadid_token"
    name="universal_leadid"
    type="hidden"
    value=""
    // ref={this.universalleadid}
  />
)

export default React.memo(LeadIDinput)
