import React, { Component } from 'react'
import range from 'lodash/range'
import get from 'lodash/get'
import cx from 'classnames'
import { months, daysInMonth, currentYearOfAge } from 'utils/dob'

import './styles.scss'

class Dob extends Component {
  constructor(props) {
    const years = range(currentYearOfAge, currentYearOfAge - 60)
    super(props)
    this.state = {
      month: 0,
      day: 0,
      year: '',
      section: 'months',
      disabled: 'disabled',
      years,
      showExpand: true,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.selectMonth = this.selectMonth.bind(this)
    this.selectDay = this.selectDay.bind(this)
  }
  componentDidMount = () => {
    let formKey
    for (formKey in this.state) {
      if (this.props.captive.data[formKey] && this.props.captive.data[formKey] !== '') {
        let val = this.props.captive.data[formKey]
        if (formKey === 'year' && val !== '') {
          val = val.toString().slice(-2)
          this.setState({
            disabled: '',
          })
        }
        this.setState({
          [formKey]: val,
        })
      }
    }
  }
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.submitDob(e.target.value)
    this.props.handleNext(this.props.captive.step)
  }
  selectMonth = (monthValue) => {
    //this.setState({month: monthValue, section: 'days'});
    monthValue = monthValue * 1
    this.props.submitData({ month: monthValue })
    this.props.handleNext(this.props.captive.step)
  }
  selectDay = (dayValue) => {
    //this.setState({day: dayValue, section: 'year'});
    this.props.submitData({ day: dayValue })
    this.props.handleNext(this.props.captive.step)
  }
  handleChange(event) {
    const isDisabled =
      event.target.value !== '' && event.target.value.length === 2 ? '' : 'disabled'
    const intValue = event.target.value * 1
    this.setState({ year: intValue, disabled: isDisabled })
  }
  determineButtonClass = (classes, id, val) => {
    return classes + (this.state[id] == val ? ' picked' : '')
  }
  determineTitle = () => {
    switch (this.props.captive.step) {
      case 'dobyear':
        return 'What is your birth year?'
      case 'dobday':
        return 'What day of the month were you born?'
      case 'dobmonth':
      default:
        return 'What month of the year were you born?'
    }
  }
  determineForm = () => {
    switch (this.props.captive.step) {
      case 'dobyear':
        return this.renderYear()
      case 'dobday':
        return this.renderDays()
      case 'dobmonth':
      default:
        return this.renderMonths()
    }
  }

  renderMonths = () => {
    return (
      <div className="months">
        {months.map(({ value, name }) => (
          <span
            key={value}
            className={this.determineButtonClass(
              'month btn btn-secondary',
              'month',
              value,
            )}
            onClick={() => this.selectMonth(value)}
          >
            {name}
          </span>
        ))}
      </div>
    )
  }
  renderDays = () => {
    const year = get(this.props.captive, 'data.year', null)
    let dayCount = daysInMonth(this.state.month, year) || 31
    for (let d = 0; d < Object.keys(months).length; d++) {
      if (months[d].value === this.state.month) {
        dayCount = months[d].days
      }
    }
    let items = []
    for (let i = 1; i <= dayCount; i++) {
      items.push(
        <span
          key={i}
          className={this.determineButtonClass('day btn btn-secondary', 'day', i)}
          onClick={() => this.selectDay(i)}
        >
          {i}
        </span>,
      )
    }
    return <div className="days">{items}</div>
  }
  renderYear = () => {
    const expandYears = range(currentYearOfAge, currentYearOfAge - 100)
    const picked = get(this.props.captive, 'data.year', null)
    return (
      <>
        <div className="year">
          {this.state.years.map((year) => (
            <button
              key={year}
              value={year}
              type="button"
              onClick={this.handleSubmit}
              className={cx('btn', 'btn-secondary', picked === year && 'picked')}
            >
              {year}
            </button>
          ))}
        </div>
        {this.state.showExpand && (
          <div>
            <br />
            <button
              type="button"
              className="btn"
              onClick={() => this.setState({ years: expandYears, showExpand: false })}
            >
              List More &rarr;
            </button>
          </div>
        )}
      </>
    )
  }

  render = () => (
    <div>
      <strong className="h4">{this.determineTitle()}</strong>
      <form
        action="#"
        id="dob"
        className={cx('form-validation', 'dob', `dob__step--${this.props.captive.step}`)}
        noValidate="novalidate"
      >
        {this.determineForm()}
      </form>
    </div>
  )
}

export default Dob
