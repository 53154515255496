import React, { Component } from 'react'
import { getValidation } from 'utils/validation'
import flatMap from 'lodash/flatMap'
import clsx from 'classnames'

import InfoBox from '../../info-box'

import { states } from './states'
import './address.scss'

const rules = {
  zip: 'required|zip',
  licensed_state: 'required',
  address: 'required',
}

class Address extends Component {
  constructor(props) {
    super(props)
    this.state = {
      address: '',
      zip: '',
      licensed_state: '',
      disabled: true,
      errors: null,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount = () => {
    if (this.props.captive.data.address && this.props.captive.data.address !== '') {
      this.setState({
        address: this.props.captive.data.address,
      })
    }

    if (this.props.captive.data.zip && this.props.captive.data.zip !== '') {
      this.setState({
        zip: this.props.captive.data.zip,
      })
    }
  }
  handleSubmit = (e) => {
    e.preventDefault()

    const data = {
      address: this.state.address,
      zip: this.state.zip,
      licensed_state: this.state.licensed_state,
    }

    const validation = getValidation({ data, rules })
    if (!validation.passes()) {
      this.setState({ errors: validation.errors })
    } else {
      this.setState({ errors: null })
      this.props.submitData(data)
      this.props.handleNext('address')
    }
  }

  checkDisabled = () => {
    const isDisabled =
      this.state.address !== '' &&
      this.state.address.length > 5 &&
      this.state.licensed_state !== '' &&
      this.state.licensed_state.length > 0 &&
      this.state.zip !== '' &&
      this.state.zip.length > 4
        ? false
        : true
    this.setState({ disabled: isDisabled })
  }

  handleChange(event) {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      this.checkDisabled,
    )
  }

  render() {
    const { errors } = this.state
    return (
      <div id="address-zip">
        {this.props.captive.type === 'health' &&
          this.props.captive.data.tobacco &&
          this.props.captive.data.tobacco === 'Yes' && (
            <InfoBox text="Don’t fret. We have affordable plans available for tobacco users." />
          )}
        <strong className="h4">Please enter your address</strong>
        <form action="#" onSubmit={this.handleSubmit} className="form-validation">
          <div className="row">
            <div className="col full-width">
              <label htmlFor="address">Address</label>
              <input
                maxLength="80"
                type="text"
                id="address"
                name="address"
                data-required="true"
                value={this.state.address}
                onChange={this.handleChange}
                className={clsx(errors && errors.first('address') && 'input-error')}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <label htmlFor="zip">Zip</label>
              <input
                maxLength="5"
                type="text"
                id="zip"
                name="zip"
                autoComplete="postal-code"
                data-required="true"
                value={this.state.zip}
                onChange={this.handleChange}
                className={clsx(errors && errors.first('zip') && 'input-error')}
              />
            </div>
            <div className="col">
              <label htmlFor="zip">State</label>
              <select
                id="licensed_state"
                name="licensed_state"
                onChange={this.handleChange}
                data-required="true"
              >
                {states.map((state) => (
                  <option value={state.value}>{state.label}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="btn-wrap text-center">
            <button
              type="submit"
              disabled={this.state.disabled ? 'disabled' : ''}
              className={`btn` + (this.state.disabled ? ` disabled` : ``)}
            >
              Continue
            </button>
          </div>
          {errors && (
            <div className="mt3">
              {flatMap(
                Object.keys(errors.errors).map((x) => errors.errors[x]),
                (text, i) => (
                  <InfoBox key={i} text={text} error />
                ),
              )}
            </div>
          )}
        </form>
      </div>
    )
  }
}

export default Address
