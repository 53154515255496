import React, { useState } from 'react'
import { func, object } from 'prop-types'
import { Helmet } from 'react-helmet'
import cx from 'classnames'
import get from 'lodash/get'

import { short, long } from './companies'
import './autoInsurance.scss'

const propTypes = {
  submitData: func.isRequired,
  handleNext: func.isRequired,
  captive: object.isRequired,
}

const AutoInsurance = ({ submitData, handleNext, captive }) => {
  const [step, setStep] = useState(0)
  const [more, setMore] = useState(false)

  const handleNoneSubmit = (e) => {
    e.preventDefault()
    submitData({
      autoInsurance: false,
      autoInsuranceCompany: '(Not Listed)',
    })
    handleNext('autoInsurance')
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    submitData({
      autoInsurance: true,
      autoInsuranceCompany: e.target.value,
    })
    handleNext('autoInsurance')
  }

  const handleSubmitNextStep = (e) => {
    submitData({
      autoInsurance: e.target.value,
    })
    setStep(1)
  }

  const picked = get(captive, 'data.autoInsurance', null)
  const pickedCompany = get(captive, 'data.autoInsuranceCompany', null)

  return (
    <>
      <div id="autoInsurance">
        <Helmet>
          <script>{`window.initiateJquery();`}</script>
        </Helmet>
        {step === 0 && (
          <>
            <strong className="h4">
              Have you had auto insurance in the past 30 days?
            </strong>
            <form
              action="#"
              className="form-validation full-width inline-buttons"
              noValidate="novalidate"
            >
              <button
                value="true"
                onClick={handleSubmitNextStep}
                type="button"
                className={cx('btn', (picked === true || picked === 'true') && 'picked')}
              >
                Yes
              </button>
              <button
                value="false"
                onClick={handleNoneSubmit}
                type="button"
                className={cx(
                  'btn',
                  (picked === false || picked === 'false') && 'picked',
                )}
              >
                No
              </button>
            </form>
          </>
        )}
        {step === 1 && (
          <>
            <strong className="h4">Current auto insurance</strong>
            <form
              action="#"
              className="form-validation"
              noValidate="novalidate"
              className={more ? 'auto-insurance-show-more-wrapper' : ''}
            >
              {more ? (
                long.map((company) => (
                  <button
                    key={company}
                    className={cx(
                      'btn',
                      'btn-secondary',
                      pickedCompany === company && 'picked',
                    )}
                    value={company}
                    onClick={handleSubmit}
                    type="button"
                  >
                    {company}
                  </button>
                ))
              ) : (
                <div className="short">
                  <div>
                    {short.map((company) => (
                      <button
                        key={company}
                        className={cx(
                          'btn',
                          'btn-secondary',
                          pickedCompany === company && 'picked',
                        )}
                        value={company}
                        onClick={handleSubmit}
                        type="button"
                      >
                        {company}
                      </button>
                    ))}
                  </div>
                  <div>
                    <br />
                    <button className="btn" onClick={() => setMore(true)}>
                      List More &rarr;
                    </button>
                  </div>
                </div>
              )}
            </form>
          </>
        )}
      </div>
      {step > 0 && (
        <span className="previous" onClick={() => setStep(step - 1)}>
          &larr;&nbsp; Previous Question
        </span>
      )}
    </>
  )
}

AutoInsurance.propTypes = propTypes

export default AutoInsurance
