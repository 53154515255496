import React from 'react'
import { func, object } from 'prop-types'
import { Helmet } from 'react-helmet'
import cx from 'classnames'
import get from 'lodash/get'

const propTypes = {
  submitData: func.isRequired,
  handleNext: func.isRequired,
  captive: object.isRequired,
}

const License = ({ submitData, handleNext, captive }) => {
  const handleSubmit = (e) => {
    e.preventDefault()
    submitData({
      license: e.target.value,
    })
    handleNext('license')
  }

  const picked = get(captive, 'data.license', null)

  return (
    <div id="license">
      <Helmet>
        <script>{`window.initiateJquery();`}</script>
      </Helmet>
      <strong className="h4">Do you have a valid drivers license?</strong>
      <form
        action="#"
        className="form-validation full-width inline-buttons"
        noValidate="novalidate"
      >
        <button
          value="Yes"
          onClick={handleSubmit}
          type="button"
          className={cx('btn', picked === 'Yes' && 'picked')}
        >
          Yes
        </button>
        <button
          value="No"
          onClick={handleSubmit}
          type="button"
          className={cx('btn', picked === 'No' && 'picked')}
        >
          No
        </button>
      </form>
    </div>
  )
}

License.propTypes = propTypes

export default License
