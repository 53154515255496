import { connect } from 'react-redux'

import Pii from './pii'

import {
  nextStep,
  preSubmitApplication,
  submitData,
  submitApplication,
} from '../../../reducers/captive'

export default connect((state) => state, {
  nextStep,
  preSubmitApplication,
  submitData,
  submitApplication,
})(Pii)
