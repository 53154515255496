import React, { useState } from 'react'
import { func, object } from 'prop-types'
import { Helmet } from 'react-helmet'
import cx from 'classnames'
import get from 'lodash/get'

// commented out steps to give details on second vehicle
// import VehicleDate from "../vehicleDate"
// import VehicleMake from "../vehicleMake"
// import VehicleModel from "../vehicleModel"
// import VehicleTrim from "../vehicleTrim"

const propTypes = {
  submitData: func.isRequired,
  handleNext: func.isRequired,
  captive: object.isRequired,
}

const VehicleSecond = ({ submitData, handleNext, captive }) => {
  // const [step, setStep] = useState(null)
  const handleSubmit = (e) => {
    e.preventDefault()
    submitData({
      additional_vehicles: e.target.value,
    })
    handleNext('vehiclesecond')
  }

  // const startSecondSteps = () => {
  //   setStep(1)
  // }

  const picked = get(captive, 'data.additional_vehicles', null)

  // if (step === 1)
  //   return (
  //     <VehicleDate
  //       nextStepSecond={() => setStep(step + 1)}
  //       prevStepSecond={() => setStep(step - 1)}
  //       second
  //     />
  //   )
  // if (step === 2)
  //   return (
  //     <VehicleMake
  //       nextStepSecond={() => setStep(step + 1)}
  //       prevStepSecond={() => setStep(step - 1)}
  //       second
  //     />
  //   )
  // if (step === 3)
  //   return (
  //     <VehicleModel
  //       nextStepSecond={() => setStep(step + 1)}
  //       prevStepSecond={() => setStep(step - 1)}
  //       second
  //     />
  //   )
  // if (step === 4)
  //   return (
  //     <VehicleTrim
  //       second
  //       submitSecond={() => handleNext("vehiclesecond")}
  //       prevStepSecond={() => setStep(step - 1)}
  //     />
  //   )

  return (
    <div id="vehicleSecond">
      <Helmet>
        <script>{`window.initiateJquery();`}</script>
      </Helmet>
      <strong className="h4">Do you have multiple vehicles?</strong>
      <form
        action="#"
        className="form-validation col full-width inline-buttons"
        noValidate="novalidate"
      >
        <button
          value="Yes"
          // onClick={startSecondSteps}
          onClick={handleSubmit}
          type="button"
          className={cx('btn', picked === 'Yes' && 'picked')}
        >
          Yes
        </button>
        <button
          value="No"
          onClick={handleSubmit}
          type="button"
          className={cx('btn', picked === 'No' && 'picked')}
        >
          No
        </button>
      </form>
    </div>
  )
}

VehicleSecond.propTypes = propTypes

export default VehicleSecond
