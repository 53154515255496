import React, { useEffect, useState } from 'react'
import { func, object, bool } from 'prop-types'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import cx from 'classnames'
import get from 'lodash/get'

import './vehicle.scss'

const defaultProps = {
  second: false,
}

const propTypes = {
  submitData: func.isRequired,
  handleNext: func,
  captive: object.isRequired,
  second: bool,
  nextStepSecond: func,
  prevStepSecond: func,
}

const VehicleMake = ({
  submitData,
  handleNext,
  captive,
  second,
  nextStepSecond,
  prevStepSecond,
}) => {
  const [makes, setMakes] = useState(null)

  useEffect(() => {
    const req = { canceled: false }
    getModels(req)
    return () => {
      req.canceled = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getModels = async (req) => {
    // const url = `https://xuo621mpt2.execute-api.us-east-1.amazonaws.com/dev/v1/carquery?year=${captive.data.vehicle_year}`
    const url = `https://rwzqgyxmfd.execute-api.us-east-1.amazonaws.com/prod/v1/makes?year=${captive.data.vehicle_year}`
    await axios(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (!req.canceled) {
        setMakes(res?.data?.data)
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (second) {
      submitData({
        vehicle_make_second: e.target.value,
      })
      nextStepSecond()
    } else {
      submitData({
        vehicle_make: e.target.value,
      })
      handleNext('vehicleMake')
    }
  }

  const picked = second
    ? get(captive, 'data.vehicle_make_second', null)
    : get(captive, 'data.vehicle_make', null)

  return (
    <>
      <div id="vehicleMake">
        <Helmet>
          <script>{`window.initiateJquery();`}</script>
        </Helmet>
        <strong className="h4">Select your {second && 'second'} vehicle make</strong>
        {makes && makes.length ? (
          <form action="#" className="form-validation" noValidate="novalidate">
            {makes.map(({ make }) => (
              <button
                key={make}
                value={make}
                onClick={handleSubmit}
                type="button"
                className={cx('btn', 'btn-secondary', picked === make && 'picked')}
              >
                {make}
              </button>
            ))}
          </form>
        ) : (
          <div>loading...</div>
        )}
      </div>
      {second && (
        <span className="previous" onClick={prevStepSecond}>
          &larr;&nbsp; Previous Question
        </span>
      )}
    </>
  )
}

VehicleMake.defaultProps = defaultProps
VehicleMake.propTypes = propTypes

export default VehicleMake
