import React from 'react'
import { func, object } from 'prop-types'
import { Helmet } from 'react-helmet'
import cx from 'classnames'
import get from 'lodash/get'

import './homeowner.scss'

const propTypes = {
  submitData: func.isRequired,
  handleNext: func.isRequired,
  captive: object.isRequired,
}

const Homeowner = ({ submitData, handleNext, captive }) => {
  const handleSubmit = (e) => {
    e.preventDefault()
    submitData({
      homeowner: e.target.value,
    })
    handleNext('homeowner')
  }

  const picked = get(captive, 'data.homeowner', null)

  return (
    <div id="homeowner">
      <Helmet>
        <script>{`window.initiateJquery();`}</script>
      </Helmet>
      <strong className="h4">Homeowner?</strong>
      <form
        action="#"
        className="form-validation full-width inline-buttons"
        noValidate="novalidate"
      >
        <button
          value="Yes"
          onClick={handleSubmit}
          type="button"
          className={cx('btn', picked === 'Yes' && 'picked')}
        >
          Own
        </button>
        <button
          value="No"
          onClick={handleSubmit}
          type="button"
          className={cx('btn', picked === 'No' && 'picked')}
        >
          Rent
        </button>
      </form>
    </div>
  )
}

Homeowner.propTypes = propTypes

export default Homeowner
