import React from 'react'
import { func, object } from 'prop-types'
import { Helmet } from 'react-helmet'
import cx from 'classnames'
import get from 'lodash/get'

import './married.scss'

const propTypes = {
  submitData: func.isRequired,
  handleNext: func.isRequired,
  captive: object.isRequired,
}

const Married = ({ submitData, handleNext, captive }) => {
  const handleSubmit = (e) => {
    e.preventDefault()
    submitData({
      married: e.target.value,
    })
    handleNext('married')
  }

  const picked = get(captive, 'data.married', null)

  return (
    <div id="married">
      <Helmet>
        <script>{`window.initiateJquery();`}</script>
      </Helmet>
      <strong className="h4">Married?</strong>
      <form
        action="#"
        className="form-validation full-width inline-buttons"
        noValidate="novalidate"
      >
        <button
          value="Married"
          onClick={handleSubmit}
          type="button"
          className={cx('btn', picked === 'Married' && 'picked')}
        >
          Yes
        </button>
        <button
          value="Single"
          onClick={handleSubmit}
          type="button"
          className={cx('btn', picked === 'Single' && 'picked')}
        >
          No
        </button>
      </form>
    </div>
  )
}

Married.propTypes = propTypes

export default Married
