import range from 'lodash/range'

export const months = [
  { value: '1', name: 'January', days: 31 },
  { value: '2', name: 'February', days: 28 },
  { value: '3', name: 'March', days: 31 },
  { value: '4', name: 'April', days: 30 },
  { value: '5', name: 'May', days: 31 },
  { value: '6', name: 'June', days: 30 },
  { value: '7', name: 'July', days: 31 },
  { value: '8', name: 'August', days: 31 },
  { value: '9', name: 'September', days: 30 },
  { value: '10', name: 'October', days: 31 },
  { value: '11', name: 'November', days: 30 },
  { value: '12', name: 'December', days: 31 },
]

export const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate()
}

export const currentYearOfAge = new Date().getFullYear() - 18

export const years = range(currentYearOfAge, currentYearOfAge - 60)

export const expandYears = range(currentYearOfAge, currentYearOfAge - 100)
