import React, { Component } from 'react'

class Gender extends Component {
  constructor(props) {
    super(props)
    this.state = {
      gender: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount = () => {
    if (this.props.captive.data.gender !== '') {
      this.setState({ gender: this.props.captive.data.gender })
    }
  }
  handleSubmit = (value) => {
    this.props.submitData({ gender: value })
    this.props.handleNext('gender')
  }
  determineButtonClass = (classes, id, val) => {
    return classes + (this.state[id] === val ? ' picked' : '')
  }

  render = () => (
    <div>
      <strong className="h4">Plese select your gender.</strong>
      <div className="row">
        <div className="col full-width inline-buttons">
          <button
            className={this.determineButtonClass('btn', 'gender', 'male')}
            onClick={() => {
              this.handleSubmit('male')
            }}
          >
            Male
          </button>
          <button
            className={this.determineButtonClass('btn', 'gender', 'female')}
            onClick={() => {
              this.handleSubmit('female')
            }}
          >
            Female
          </button>
        </div>
      </div>
    </div>
  )
}

export default Gender
