import React from 'react'
import PropTypes from 'prop-types'

const Intro = ({
  children,
  showTitle,
  showSubTitle,
  bgImage,
  colType,
  title,
  subTitle,
  classNames,
  showInfoBox,
}) => {
  classNames = 'intro-section bg-intro alt ' + classNames
  return (
    <div className={classNames} style={{ backgroundImage: `url('${bgImage}')` }}>
      <div className="container">
        <div className={colType}>
          {showTitle && (
            <div className="title-holder">
              <h1>{title}</h1>
              {showSubTitle && <p>{subTitle}</p>}
            </div>
          )}
          {children}
        </div>
        {showInfoBox && (
          <div className="right-col form-box">
            <h3>We’re here to help.</h3>
            <ul>
              <li>Finding a quote with Health Insurance Companion is totally free.</li>
              <li>We’ve helped thousands find affordable health insurance.</li>
              <li>Getting a quote takes less than 5 minutes of your time.</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

Intro.propTypes = {
  children: PropTypes.node,
  showTitle: PropTypes.bool,
  showSubTitle: PropTypes.bool,
  bgImage: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  classNames: PropTypes.string,
  showInfoBox: PropTypes.bool,
}

Intro.defaultProps = {
  showTitle: true,
  showSubTitle: true,
  bgImage: '/images/benefit-bg.png',
  colType: 'left-col',
  title: 'Find affordable auto insurance now.',
  subTitle:
    'First, Just answer a few simple questions to start comparing auto insurance quotes.',
  classNames: '',
  showInfoBox: false,
}

export default Intro
