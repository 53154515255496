import React, { useState } from 'react'
import get from 'lodash/get'
import { func, object } from 'prop-types'
import { Helmet } from 'react-helmet'
import cx from 'classnames'

import './accidents.scss'

const propTypes = {
  handleNext: func.isRequired,
  submitData: func.isRequired,
  captive: object.isRequired,
}

const Accidents = ({ handleNext, submitData, captive }) => {
  const initialFormState = {
    violations: get(captive, 'data.violations', null),
    tickets_accidents_claims_past_3_years: get(
      captive,
      'data.tickets_accidents_claims_past_3_years',
      null,
    ),
    dui_dwi: get(captive, 'data.dui_dwi', null),
  }
  const [step, setStep] = useState(0)
  const [form, setForm] = useState(initialFormState)

  const handleNoneSubmit = (e) => {
    e.preventDefault()
    submitData({
      violations: '0',
      tickets_accidents_claims_past_3_years: 'No',
      dui_dwi: 'No',
    })
    handleNext('accidents')
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    submitData({ ...form, dui_dwi: e.target.value })
    handleNext('accidents')
  }

  const handleSubmitNextStep = ({ target: { name, value } }) => {
    setForm((form) => ({ ...form, [name]: value }))
    setStep(step + 1)
  }

  return (
    <>
      <div id="accidents">
        <Helmet>
          <script>{`window.initiateJquery();`}</script>
        </Helmet>
        {step === 0 && (
          <>
            <strong className="h4">Are you a good driver?</strong>
            <p>(incident free in the last 3 years)</p>
            <form
              action="#"
              className="form-validation full-width inline-buttons"
              noValidate="novalidate"
            >
              <button
                value="No"
                type="button"
                name="tickets_accidents_claims_past_3_years"
                onClick={handleNoneSubmit}
                className={cx(
                  'btn',
                  form.tickets_accidents_claims_past_3_years === 'No' && 'picked',
                )}
              >
                Yes
              </button>
              <button
                value="No"
                name="tickets_accidents_claims_past_3_years"
                value="Yes"
                onClick={handleSubmitNextStep}
                type="button"
                className={cx(
                  'btn',
                  form.tickets_accidents_claims_past_3_years === 'Yes' && 'picked',
                )}
              >
                No
              </button>
            </form>
          </>
        )}
        {step === 1 && (
          <>
            <strong className="h4">
              How many tickets or accidents in the past 3 years?
            </strong>
            <form action="#" className="form-validation" noValidate="novalidate">
              <button
                value="1"
                name="violations"
                onClick={handleSubmitNextStep}
                type="button"
                className={cx(
                  'btn',
                  'btn-secondary',
                  form.violations === '1' && 'picked',
                )}
              >
                1
              </button>
              <button
                value="2"
                name="violations"
                onClick={handleSubmitNextStep}
                type="button"
                className={cx(
                  'btn',
                  'btn-secondary',
                  form.violations === '2' && 'picked',
                )}
              >
                2
              </button>
              <button
                value="3"
                name="violations"
                onClick={handleSubmitNextStep}
                type="button"
                className={cx(
                  'btn',
                  'btn-secondary',
                  form.violations === '3' && 'picked',
                )}
              >
                3
              </button>
              <button
                value="4"
                name="violations"
                onClick={handleSubmitNextStep}
                type="button"
                className={cx(
                  'btn',
                  'btn-secondary',
                  form.violations === '4' && 'picked',
                )}
              >
                4
              </button>
              <button
                value="5"
                name="violations"
                onClick={handleSubmitNextStep}
                type="button"
                className={cx(
                  'btn',
                  'btn-secondary',
                  form.violations === '5' && 'picked',
                )}
              >
                5+
              </button>
            </form>
          </>
        )}
        {step === 2 && (
          <>
            <strong className="h4">Any DUI charges?</strong>
            <form
              action="#"
              className="form-validation full-width inline-buttons"
              noValidate="novalidate"
            >
              <button
                value="Yes"
                name="dui_dwi"
                onClick={handleSubmit}
                type="button"
                className={cx('btn', form.dui_dwi === 'Yes' && 'picked')}
              >
                Yes
              </button>
              <button
                value="No"
                name="dui_dwi"
                onClick={handleSubmit}
                type="button"
                className={cx('btn', form.dui_dwi === 'No' && 'picked')}
              >
                No
              </button>
            </form>
          </>
        )}
      </div>
      {step > 0 && (
        <span className="previous" onClick={() => setStep(step - 1)}>
          &larr;&nbsp; Previous Question
        </span>
      )}
    </>
  )
}

Accidents.propTypes = propTypes

export default Accidents
