import React from 'react'
import { func, object } from 'prop-types'
import { Helmet } from 'react-helmet'
import cx from 'classnames'
import get from 'lodash/get'

import { options } from './options'
import './credit.scss'

const propTypes = {
  submitData: func.isRequired,
  handleNext: func.isRequired,
  captive: object.isRequired,
}

const Credit = ({ submitData, handleNext, captive }) => {
  const handleSubmit = (e) => {
    e.preventDefault()
    submitData({
      credit: e.target.value,
    })
    handleNext('credit')
  }

  const picked = get(captive, 'data.credit', null)

  return (
    <div id="credit">
      <Helmet>
        <script>{`window.initiateJquery();`}</script>
      </Helmet>
      <strong className="h4">What is your credit score?</strong>
      <form action="#" className="form-validation" noValidate="novalidate">
        {options.map((option) => (
          <button
            key={option.value}
            value={option.value}
            onClick={handleSubmit}
            type="button"
            className={cx(
              'btn',
              'btn-secondary',
              parseInt(picked, 10) === parseInt(option.value, 10) && 'picked',
            )}
          >
            {option.label}
          </button>
        ))}
      </form>
    </div>
  )
}

Credit.propTypes = propTypes

export default Credit
