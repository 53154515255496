import { connect } from 'react-redux'

import VehicleMake from './vehicle'

import { submitData } from '../../../reducers/captive'

export default connect(
  (state) => ({
    captive: state.captive,
  }),
  {
    submitData,
  },
)(VehicleMake)
