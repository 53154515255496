import { connect } from 'react-redux'

import homeowner from './homeowner'

import { submitData } from '../../../reducers/captive'

export default connect(
  (state) => ({
    captive: state.captive,
  }),
  {
    submitData,
  },
)(homeowner)
