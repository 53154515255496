import React from 'react'
// import { Link } from "gatsby"

import Layout from '../components/layout'
import SEO from '../components/seo'
import Intro from '../components/sections/intro'
import Faqs from '../components/sections/faqs'
import Partners from '../components/sections/partners'
import Main from '../components/apply/main'
import Header from '../components/header'

import AutoBg from '../images/auto-hands.jpg'

const ApplyPage = ({ location }) => (
  <Layout phone="888-996-1365" location={location}>
    <SEO
      title="Apply for Auto Insurance"
      keywords={[`auto`, `insurance`, `plans`, `affordable`]}
    />
    <Header phone="888-996-1365" />
    <Intro showTitle={false} colType="full-col" bgImage={AutoBg}>
      <Main location={location} />
    </Intro>
    <main id="main">
      <Partners />
      <Faqs />
    </main>
  </Layout>
)

export default ApplyPage
