import React, { useEffect, useState } from 'react'
import { func, object, bool } from 'prop-types'
import { Helmet } from 'react-helmet'
import axios from 'axios'
import cx from 'classnames'
import get from 'lodash/get'

import './vehicle.scss'

const defaultProps = {
  second: false,
}

const propTypes = {
  submitData: func.isRequired,
  handleNext: func,
  captive: object.isRequired,
  second: bool,
  nextStepSecond: func,
  prevStepSecond: func,
}

const VehicleModel = ({
  submitData,
  handleNext,
  captive,
  second,
  nextStepSecond,
  prevStepSecond,
}) => {
  const [models, setModels] = useState(null)

  useEffect(() => {
    const req = { canceled: false }
    getModels(req)
    return () => {
      req.canceled = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getModels = async (req) => {
    // const url = `https://xuo621mpt2.execute-api.us-east-1.amazonaws.com/dev/v1/carquery?year=${captive.data.vehicle_year}&make=${captive.data.vehicle_make}`

    const url = `https://rwzqgyxmfd.execute-api.us-east-1.amazonaws.com/prod/v1/models?year=${captive.data.vehicle_year}&make=${captive.data.vehicle_make}`
    await axios(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => {
      if (!req.canceled) {
        setModels(res?.data?.data)
      }
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (second) {
      submitData({
        vehicle_model_second: e.target.value,
      })
      nextStepSecond()
    } else {
      submitData({
        vehicle_model: e.target.value,
      })
      handleNext('vehicleModel')
    }
  }

  const picked = second
    ? get(captive, 'data.vehicle_model_second', null)
    : get(captive, 'data.vehicle_model', null)

  return (
    <>
      <div id="vehicleModel">
        <Helmet>
          <script>{`window.initiateJquery();`}</script>
        </Helmet>
        <strong className="h4">Select your {second && 'second'} vehicle model</strong>
        {models && models.length ? (
          <form action="#" className="form-validation" noValidate="novalidate">
            {models.map(({ model }) => (
              <button
                key={model}
                value={model}
                onClick={handleSubmit}
                type="button"
                className={cx('btn', 'btn-secondary', picked === model && 'picked')}
              >
                {model}
              </button>
            ))}
          </form>
        ) : (
          <div>loading...</div>
        )}
      </div>
      {second && (
        <span className="previous" onClick={prevStepSecond}>
          &larr;&nbsp; Previous Question
        </span>
      )}
    </>
  )
}

VehicleModel.defaultProps = defaultProps
VehicleModel.propTypes = propTypes

export default VehicleModel
